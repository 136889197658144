// Forms

.form-control-xs,
.form-select-xs {
  padding: $input-padding-y-xs $input-padding-x-xs;
  font-size: $input-font-size-xs;
}

.form-control-xs {
  + .input-group-text {
    padding-top: $input-padding-y-xs;
    padding-bottom: $input-padding-y-xs;
  }
}

.form-check-input {
  margin-top: 0.13em; // height adjustment
}

.input-group-text {
  svg {
    width: 18px;
    height: 18px;
  }
}

// For RTL
[type='tel'],
[type='url'],
[type='email'],
[type='number'] {
  direction: ltr;
}

.disabled {
  background-color: #e9ecef;
  opacity: 1;
  pointer-events: none;
}
.disabled:focus {
  color: #000;
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}
