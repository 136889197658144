// npm package: dropify
// github link: https://github.com/JeremyFagis/dropify

.dropify-wrapper {
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  .dropify-message {
    span {
      &.file-icon {
        font-size: 0.875rem;
        color: $text-muted;
        &::before {
          font-family: feather;
          content: '\e8e3';
          font-size: 24px;
        }
      }
    }
  }
}
