@media (max-width: 768px) {
  .table td {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3.6em; /* Limita a 2 líneas */
    line-height: 1.8em; /* Ajusta la altura de línea para controlar el tamaño */
  }
}

/* Estilo para pantallas de escritorio */
@media (min-width: 769px) {
  .table td {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 5.4em; /* Limita a 3 líneas */
    line-height: 1.8em; /* Ajusta la altura de línea para controlar el tamaño */
  }
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  right: 20px;
}

#loader-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1100;
}

.buttons-theme-color {
  .active {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 1);
  }
}
