.login-client {
  .left-aside-login {
    background-image: url('/images/left-side-login.jpg');
    // background-position: right top;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    color: #fff;
    height: 100%;
    position: fixed;
    width: 45%;
    .carousel-login {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      .carousel-indicators {
        bottom: -50px;
      }
    }
  }
  .auth-form {
    min-height: 100vh;
    .auth-form-wrapper {
      width: 560px;
      margin: 0 auto;
    }
  }
}

.logo-login {
  width: 250px;
}
